import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ShiftsAdd } from '../Shifts';
import { shiftsActions } from '../_actions';
import { Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { miscService } from '../_services/misc.service';

const BlockBookingListView = props => {
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    shifts_from_date: '',
    isbloackbookinglist: true, //todays date----
    isverified: 1,
    active_status: 1,
  });
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [ShiftData, setShiftData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [listViewShiftAddDate, setlistViewShiftAddDate] = useState(false);
  const [listViewShiftAdd, setlistViewShiftAdd] = useState(false);
  const [showListViewShiftsAddModal, setshowListViewShiftsAddModal] =
    useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isNodataFound, setisNodataFound] = useState(false);
  // const [ListAndPagination, setListAndPagination] = useState([]);
  const [deleteconfirmationmodal, setdeleteconfirmationmodal] = useState(false);
  const [shiftid, setshiftid] = useState([]);
  let daterows = [];
  // change 24 ti 12 hours format

  // get actual hours format like 29.97 to 29.5
  const getActualHourFormat = hours => {
    let hoursPart = Math.floor(hours);
    let minutesPart = Math.floor((hours - hoursPart) * 60);
    return hoursPart + '.' + minutesPart;
  };

  //To find the date which are less than currentday for add shift
  function isFutureDate(date) {
    const currentDate = new Date();
    const yesterdayDate = new Date();
    yesterdayDate.setDate(currentDate.getDate() - 1);
    const providedDate = new Date(date);
    return providedDate >= yesterdayDate;
  }

  //to find matching of shifts date with calendar this function
  const funshifts = (shiftdata, i) => {
    let returnObject = [];
    for (
      let s = 0;
      shiftdata.shifts && shiftdata.shifts[0] && s < shiftdata.shifts.length;
      s++
    ) {
      if (shiftdata.shifts[s].from_date == daterows[i]) {
        returnObject.push(
          <>
            <div
              className={
                shiftdata.shifts[s] &&
                shiftdata.shifts[s].is_attended &&
                shiftdata.shifts[s].is_attended == 1
                  ? shiftdata.shifts[s].section_type == 'Morning'
                    ? ' section-div morning-color'
                    : shiftdata.shifts[s].section_type == 'Night'
                    ? ' section-div night-color'
                    : ' section-div custom-color'
                  : shiftdata.shifts[s].section_type == 'Morning'
                  ? ' section-div-not_verified '
                  : shiftdata.shifts[s].section_type == 'Night'
                  ? ' section-div-not_verified '
                  : ' section-div-not_verified '
              }
            >
              <div className="ml-2 h40">
                {shiftdata.shifts[s].section_type == 'Morning'
                  ? 'Early'
                  : shiftdata.shifts[s].section_type == 'Evening'
                  ? 'Late'
                  : shiftdata.shifts[s].section_type}
              </div>
              {moment(moment().format('YYYY-MM-DD')).isBefore(
                shiftdata.shifts[s].from_date,
                'day',
              ) && !shiftdata.shifts[s].is_verified ? (
                <div
                  className="section-type-close"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    deleteshiftmodal(shiftdata.shifts[s].shift_id);
                  }}
                >
                  x{' '}
                </div>
              ) : (
                <div
                  className="section-type-check"
                  style={{
                    cursor: 'pointer',
                    background: '#a5abbc',
                    color: '#fff',
                  }}
                >
                  <div>
                    {shiftdata.shifts[s] &&
                    shiftdata.shifts[s].is_attended &&
                    shiftdata.shifts[s].is_attended == 1 ? (
                      <img
                        className="check_img"
                        src="/assets/img/check22.png"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              )}
            </div>
            <div style={{ fontSize: '9px', color: '#a4a4a4' }}>
              {miscService.time24to12(shiftdata.shifts[s].check_in_time_string)}
              -{' '}
              {miscService.time24to12(
                shiftdata.shifts[s].check_out_time_string,
              )}
            </div>
          </>,
        );
      }
    }
    // this checks the unavailablity -
    for (
      let s = 0;
      shiftdata.availability &&
      shiftdata.availability[0] &&
      s < shiftdata.availability.length;
      s++
    ) {
      if (
        shiftdata.availability[s].available_date_string == daterows[i] &&
        shiftdata.availability[s].unavailable_available == 1
      ) {
        returnObject = (
          <div className="add-button-div-shift">
            <div className="icon-weekly">
              <div style={{ color: '#a2a2a2' }}>unavailable</div>
            </div>
          </div>
        );
      }
    }
    if (returnObject.length == 0) {
      returnObject = (
        <>
          <div className="add-button-div-shift">
            <div className="icon-weekly">
              <i
                className="fa fa-plus-circle"
                data-cy="addshiftt"
                onClick={() => {
                  if (props.IsAdmin || props.IsStaff) {
                    showAllocateShiftListView(daterows[i], i, shiftdata);
                  } else if (!props.IsEmployer && isFutureDate(daterows[i])) {
                    showAllocateShiftListView(daterows[i], i, shiftdata);
                  }
                }}
                style={{
                  display:
                    props.IsAdmin || props.IsStaff
                      ? 'inline-block'
                      : !props.IsEmployer && isFutureDate(daterows[i])
                      ? 'inline-block'
                      : 'none',
                }}
              ></i>
              {/*<img
                data-cy="addshiftt"
                onClick={() => {
                  if (props.IsAdmin || props.IsStaff) {
                    showAllocateShiftListView(daterows[i], i, shiftdata);
                  } else if (!props.IsEmployer && isFutureDate(daterows[i])) {
                    showAllocateShiftListView(daterows[i], i, shiftdata);
                  }
                }}
                className="plus_img"
                src="/assets/img/plus22.png"
                style={{
                  display:
                    props.IsAdmin || props.IsStaff
                      ? 'inline-block'
                      : !props.IsEmployer && isFutureDate(daterows[i])
                      ? 'inline-block'
                      : 'none',
                }}
              />*/}
            </div>
          </div>
          {availabilitySectionCheck(daterows[i], i, shiftdata)}
        </>
      );
    }
    return returnObject;
  };
  // to check the section is available for shift
  const availabilitySectionCheck = (daterows, i, shiftdata) => {
    for (
      let s = 0;
      shiftdata.availability &&
      shiftdata.availability[0] &&
      s < shiftdata.availability.length;
      s++
    ) {
      if (
        shiftdata.availability[s].available_date_string == daterows &&
        shiftdata.availability[s].unavailable_available == 0
      ) {
        return (
          <div className="mt-3">
            {shiftdata.availability[s].day_available !== 0 && (
              <img
                className="check_img_attended"
                src="/assets/img/greencircle.png"
              />
            )}
            {shiftdata.availability[s].morning_available !== 0 && (
              <img
                className="check_img_attended"
                src="/assets/img/redcircle.png"
              />
            )}
            {shiftdata.availability[s].night_available !== 0 && (
              <img
                className="check_img_attended"
                src="/assets/img/yellowcircle.png"
              />
            )}
            {shiftdata.availability[s].evening_available !== 0 && (
              <img
                className="check_img_attended"
                src="/assets/img/bluecircle.png"
              />
            )}
          </div>
        );
      }
    }
  };
  // to show shifts add modal
  const showAllocateShiftListView = (date, i, shiftdata) => {
    setlistViewShiftAddDate({ date: date, index: i });
    setlistViewShiftAdd({ date: date, index: i, shiftdata: shiftdata });
    setshowListViewShiftsAddModal(true);
  };
  // ---CALENDAR SINGLE ITEM------VIEW
  const getColoumItem = (i, shiftdata) => {
    return <div className="item_weekly">{funshifts(shiftdata, i)}</div>;
  };
  //---------------handlesort --------------
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  function daysOfMonth() {
    const currentDate = new Date(props.shifts_from_date);
    const datetoday = currentDate.getDate(); //todays current date "dd"
    let monthtoday = currentDate.getMonth(); //current date's month
    const yeartoday = currentDate.getFullYear(); //current date's month
    const daytoday = currentDate.getDay();
    let tableRows = [];
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    //get no of days in month
    //get current day start with that day
    //---
    //first row of calendar
    for (let i = daytoday, j = 0; j < 7; i++, j++) {
      //----the value 7 indicated show calendar for next 7 days ---------
      //get next day after....ie for the next 7 days---------
      let nextdate = new Date(currentDate);
      nextdate.setDate(nextdate.getDate() + j);
      const dateNextday = nextdate.getDate(); //todays current date "dd"
      const monthNextday = nextdate.getMonth(); //current date's month
      const yearNextday = nextdate.getFullYear();
      //---================---------------TABLE HEADING START-==========================
      <tr className="col-md-12 p-2 mt-2 th-stciky border-top1 bg-fff ">
        {j == 0 &&
          tableRows.push(
            <>
              {/* push this on first 2 cols of the table only of the value of j is 0 */}
              <td className="col-md-1 calendar-day  fixedcol th-first p-1">
                <th
                  onClick={() => handleSort('candidate_name')}
                  className="sortHead border-top-0"
                >
                  Name{' '}
                  <span className={'sortBtn '}>
                    {' '}
                    <i
                      className={
                        'fa fa-sort-up ' +
                        (query.pageVo.sort == 'candidate_name' &&
                        query.pageVo.sort_order != 'desc'
                          ? ' active'
                          : '')
                      }
                    ></i>{' '}
                    <i
                      className={
                        'fa fa-sort-down ' +
                        (query.pageVo.sort == 'candidate_name' &&
                        query.pageVo.sort_order == 'desc'
                          ? ' active'
                          : '')
                      }
                    ></i>
                  </span>
                </th>
              </td>
              <td className="col-md-1 calendar-day  fixedcol2 th-second">
                <div> Total working hrs</div>
              </td>
            </>,
          )}
        {tableRows.push(
          <td className="col-md-1 calendar-day tdscroll ">
            <div className="scroll-cal-head">{days[i]}</div>
            {/* today, tommorow, day after tommorow ......goes on--- */}
            <div className="scroll-cal-head">
              {j == 0
                ? //first day block starts----
                  (datetoday.toString().length == 1
                    ? '0' + datetoday
                    : datetoday) +
                  '-' +
                  ((monthtoday + 1).toString().length == 1
                    ? '0' + (monthtoday + 1)
                    : monthtoday + 1) +
                  '-' +
                  yeartoday
                : //------------next days block start-----
                  (dateNextday.toString().length == 1
                    ? '0' + dateNextday
                    : dateNextday) +
                  '-' +
                  ((monthNextday + 1).toString().length == 1
                    ? '0' + (monthNextday + 1)
                    : monthNextday + 1) +
                  '-' +
                  yearNextday}
            </div>
          </td>,
        )}
        {daterows.push(
          j == 0
            ? //first day block starts----
              //---year---
              yeartoday +
                '-' +
                //----month--
                ((monthtoday + 1).toString().length == 1
                  ? '0' + (monthtoday + 1)
                  : monthtoday + 1) +
                '-' +
                // ---day---
                (datetoday.toString().length == 1 ? '0' + datetoday : datetoday)
            : //next days block start
              //---year---
              yearNextday +
                '-' +
                //----day---
                //------month---
                ((monthNextday + 1).toString().length == 1
                  ? '0' + (monthNextday + 1)
                  : monthNextday + 1) +
                '-' +
                (dateNextday.toString().length == 1
                  ? '0' + dateNextday
                  : dateNextday),
        )}
      </tr>;
      // daterows.push("1")
      // eslint-disable-next-line no-self-assign
      i == 6 ? (i = -1) : (i = i);
    }
    // ------===============TABLE BODY START==========-------------
    //---rows is no of users in the block booking list ----
    for (
      let r = 0;
      ShiftData && ShiftData.rows && r < ShiftData.rows.length;
      r++
    ) {
      let tabeColoms = [];
      tabeColoms.push(
        <>
          <td className="fixedcol bg-fff th-first ">
            <div>
              <div className="d-flex align-items-center">
                <div>
                  {ShiftData.rows[r].profile_image &&
                  ShiftData.rows[r].profile_image != null ? (
                    <img
                      className=" round-emp-image"
                      src={
                        apiConstants.imgUrl + ShiftData.rows[r].profile_image
                      }
                    />
                  ) : (
                    <img
                      className="staff_img"
                      src="/assets/img/recsy-fav-icon.png "
                    ></img>
                  )}
                </div>
                <div className="lh-20">
                  <Link to={'/candidateprofile/' + ShiftData.rows[r]._id}>
                    <div
                      style={{ color: 'black', fontSize: '12px' }}
                      className="username"
                    >
                      {ShiftData.rows[r].name}{' '}
                      {ShiftData.rows[r].last_name
                        ? ShiftData.rows[r].last_name
                        : ''}
                    </div>
                  </Link>
                </div>
              </div>
              <div>
                <div className="categoryview ">
                  {ShiftData.rows[r].Category &&
                  ShiftData.rows[r].Category.name.length > 20
                    ? ShiftData.rows[r].Category.name.substring(0, 20) + '...'
                    : ShiftData.rows[r].Category.name}
                </div>
                <div className="d-flex categoryview mt-2 align-items-center">
                  <img
                    className=" flx-location-icon-container"
                    src="/assets/img/location-icon.svg"
                  />
                  {ShiftData.rows[r].postcode && ShiftData.rows[r].postcode}
                </div>
              </div>
            </div>
          </td>
          <td className="fixedcol2 bg-fff th-second">
            <div className="text-center">
              {getActualHourFormat(
                ShiftData.rows[r].total_shift_hours.toFixed(2),
              )}
              &nbsp;hr
            </div>
          </td>
        </>,
      );
      //---show next 7 days data ...--------
      for (let i = 0; i <= 6; i++) {
        tabeColoms.push(
          <>
            <td className="c bg-fff p-1 td_weekly">
              {getColoumItem(i, ShiftData.rows[r])}
            </td>
          </>,
        );
      }
      {
        tabeColoms.length !== 0 &&
          tableRows.push(<tr className="h130 bg-fff ">{tabeColoms}</tr>);
      }
    }
    return tableRows;
  }

  const isDataListEmpty = () => {
    return ShiftData && ShiftData.rows && ShiftData.rows.length === 0;
  };
  // effect all shifts
  useEffect(() => {
    getAllShifts(1);
  }, [query]);
  // close modal to send props function
  const showAddshiftmodal = () => {
    setshowListViewShiftsAddModal(false);
    getAllShifts(1);
  };
  // to show delete confirmation modal
  const deleteshiftmodal = id => {
    setdeleteconfirmationmodal(true);
    setshiftid({ _id: id, status: 0 });
    //get all weekly again
    getAllShifts(1);
  };
  // delete shift call
  const deleteshift = () => {
    const { dispatch } = props;
    dispatch(shiftsActions.shiftsDelete(shiftid)).then(res => {
      if (res) {
        setdeleteconfirmationmodal(false);
        getAllShifts(1);
      }
    });
  };
  // get all shifts on weekly basis
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    //-----set employer id from front end---------
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        queryNew.employer_id = user.employer_id;
      }
    }
    const { dispatch } = props;
    queryNew.shifts_from_date = props.shifts_from_date;
    if (props && props.selectedbusinessunit) {
      queryNew.business_unit = props.selectedbusinessunit;
    }
    if (props && props.searchKey) {
      queryNew.searchKey = props.searchKey;
    }
    dispatch(shiftsActions.shiftsListWeekly(queryNew)).then(
      res => {
        if (res && res.data && res.data.length == 0) {
          setisNodataFound(true);
        }
        setIsLoading(false);
        setShiftData(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  return (
    <div>
      <div className="d-flex">
        <span className="pr-1">
          <img className="check_img_attended" src="/assets/img/check22.png" />
          Attended
        </span>
        &emsp;&ensp; &emsp;&ensp;
        <p style={{ color: '#a1a1a1' }}>available in </p>
        <span className="pr-1 pl-2">
          <img
            className="check_img_attended filter_img"
            src="/assets/img/redcircle.png"
          />
          Early
        </span>
        <span className="pr-1 pl-2">
          <img
            className="check_img_attended"
            src="/assets/img/greencircle.png"
          />
          Day
        </span>
        <span className="pr-1 pl-2">
          <img
            className="check_img_attended"
            src="/assets/img/bluecircle.png"
          />
          Evening
        </span>
        <span className="pr-1 pl-2">
          <img
            className="check_img_attended"
            src="/assets/img/yellowcircle.png"
          />
          Night
        </span>
      </div>
      <div className="table-scroll-div scrollable-table-container">
        <table className="table customtable_calendar tablescroll table-hover border-top1  ">
          {daysOfMonth(5, 2022)}
        </table>
        {isDataListEmpty() && (
          <div className="d-flex justify-content-center">
            No Allocated Candidates are Available.
          </div>
        )}
        {showListViewShiftsAddModal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="close">
                    <a onClick={() => setshowListViewShiftsAddModal(false)}>
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </a>
                  </div>
                </div>
                <div className="modal-body">
                  <ShiftsAdd
                    showAddshiftmodal={showAddshiftmodal}
                    listViewShiftsAdd={true}
                    listViewShiftAddDate={listViewShiftAdd}
                    isSelectUsershow={true}
                    iscarehome={true}
                    isHideuser={true}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {deleteconfirmationmodal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-xsm">
              <div className="modal-content">
                <div className="modal-header ">
                  <div className="close">
                    <a
                      onClick={() => {
                        setdeleteconfirmationmodal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </a>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="bag">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-8">
                        <div className="d-flex justify-content-center">
                          <img
                            className="delete-icon"
                            src="/assets/img/bin.png"
                          ></img>
                        </div>
                        <p className="delete_string" style={{ color: 'black' }}>
                          Are you sure you want to delete shift?{' '}
                        </p>
                        <div className="d-flex justify-content-center">
                          <button
                            className="button_delete width_100"
                            onClick={() => {
                              setdeleteconfirmationmodal(false);
                            }}
                          >
                            Close
                          </button>
                          <button
                            className="button_delete_close width_100"
                            onClick={() => deleteshift()}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="card-footer">
        <Pagination
          pageOnClick={data => {
            getAllShifts(data);
          }}
          paginationData={ShiftData}
        />
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedBlockBookingListView =
  connect(mapStateToProps)(BlockBookingListView);
export { connectedBlockBookingListView as BlockBookingListView };
