import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BlockBookingListView, BlockBookingcalnedar } from '../Calendar';
import { ShiftsAdd } from '../Shifts';
import { candidateActions } from '../_actions';
import { LoadingImg } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import useDebounce from '../_helpers/useDebounce';
import { AttendaceBloackbooking } from './AttendanceBloackbooking';

const BlockBookinglist = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [candidateData, setCandidateData] = useState({ rows: [] });
  const [showmodal, setshowmodal] = useState(false);
  const [IsBusinessUnit, setIsBusinessUnit] = useState(false);
  const [IsAdmin, setIsAdmin] = useState(false);
  const [IsStaff, setIsStaff] = useState(false);
  const [showAllocatemodal, setshowAllocatemodal] = useState(false);
  const [selectedCol, setselectedCol] = useState(null);
  const [listViewShiftAddDate, setlistViewShiftAddDate] = useState(false);
  const [shifts_from_date, setshifts_from_date] = useState(
    moment().startOf('week').format('YYYY-MM-DD'),
  );
  const [daymonthyear, setdaymonthyear] = useState({
    day: '',
    month: '',
    year: '',
    i: '',
  });
  const [selectedbusinessunit, setselectedbusinessunit] = useState();
  const [businessunitoptionsData, setbusinessunitoptionsData] = useState([]);
  const [calendar, setcalendar] = useState(false);
  const [shifts, setshifts] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [multipleshift, setmultipleshift] = useState(false); //---is true if multiple shift add modal is visible---
  const [showshiftAddmodal, setshowshiftAddmodal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [datevalue, setdateValue] = useState(new Date());
  const [showErrorModal, setshowErrorModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [ModalError, setModalError] = useState('');
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  //--- debouncing for searchkey only - start ---
  let isfirsttime = useRef(true);
  const debouncedSearch = useDebounce(query?.searchKey, 500);
  // effect to call candidates
  useEffect(() => {
    if (!isfirsttime.current) {
      if (debouncedSearch) {
        getAllCandidate(1);
      }
    }
  }, [debouncedSearch]);
  //--- debouncing end ---

  useEffect(() => {
    if (props.isHomepage) {
      setshifts(true);
      setcalendar(false);
    }
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 7) {
        setIsBusinessUnit(true);
      }
      if (user.role == 4) {
        // setIsEmployer(true);
      }
      if (user.role == 1) {
        setIsAdmin(true);
      }
      if (user.role == 6) {
        setIsStaff(true);
      }
    }
    getAllCandidate(1);
    getbusinessunit();
  }, [shifts_from_date, selectedbusinessunit]);
  /**
   * Get all candidates
   **/
  const getAllCandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(candidateActions.candidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination({ rows: res.data.rows });
        setCandidateData({ rows: res.data.rows });
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for change bunit
  const businessunitChange = event => {
    setselectedbusinessunit(event.target.value);
  };
  // hanlder for filters
  const handleChange = event => {
    const { name, value } = event.target;
    if (name == 'searchKey') {
      isfirsttime.current = false;
      setQuery(prevstate => ({ ...prevstate, searchKey: value }));
    }
  };
  // to change the format
  const datechange = date => {
    setshifts_from_date(
      date.year +
        '-' +
        (date.month.number.toString().length == 1
          ? '0' + date.month.number
          : date.month.number) +
        '-' +
        (date.day.toString().length == 1 ? '0' + date.day : date.day),
    );
  };
  // modal to show allocate shift
  const showAllocateShift = (day, month, year, i) => {
    setdaymonthyear({ day: day, month: month, year: year, index: i }, () =>
      console.log('setstate is done for day month year----'),
    );

    setshowAllocatemodal(true);
  };
  // allocate shift view modal open
  const showAllocateShiftListView = (date, i) => {
    setlistViewShiftAddDate({ date: date, index: i });
    setshowAllocatemodal(true);
  };
  // to show calendar view
  const showcalendar = () => {
    setcalendar(true);
    setshifts(false);
  };
  // to show list view
  const showshifts = () => {
    setcalendar(false);
    setshifts(true);
  };
  // to show multiple shift modal
  const showmultipleshiftmodal = () => {
    setshowshiftAddmodal(true);
    setmultipleshift(true);
  };
  // add shift modal
  const showAddshiftmodal = () => {
    setshowshiftAddmodal(false);
    setshowAllocatemodal(false);
  };
  // ---to get all businessunit----------------
  const getbusinessunit = () => {
    let employer_id = '';
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      employer_id = user.employer_id;
    }
    if (employer_id) {
      fetch(apiConstants.apiUrl + '/getbusinessunitlist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          employer_id: employer_id,

          select: ['_id', 'name'],
        }),
      }).then(response =>
        response
          .json()

          .then(({ data }) => {
            if (data && data.rows) {
              let businessunit_option_rows = [];
              for (let s = 0; s < data.rows.length; s++) {
                businessunit_option_rows.push(
                  <option value={data.rows[s]._id}>{data.rows[s].name}</option>,
                );
              }
              setbusinessunitoptionsData(businessunit_option_rows);
            }
          }),
      );
    }
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = candidateData => {
    let candidateList = [];
    for (
      let j = 0;
      candidateData && candidateData.rows && j < candidateData.rows.length;
      j = j + 1
    ) {
      candidateList.push(
        <tr key={j}>
          <td>
            <div className="d-flex">
              <div>
                <img
                  className="staff_img"
                  src="/assets/img/candidate.jpg"
                ></img>
              </div>
              <div className="lh-20">
                <Link to={'/candidateprofile/' + candidateData.rows[j]._id}>
                  <div className="username">{candidateData.rows[j].name}</div>
                </Link>
                <div className="fontsize-8">-</div>
                <div className="categoryview">
                  {candidateData.rows[j].category}
                </div>
              </div>
            </div>
          </td>
          <td>total working hours</td>
          <td className="eventCell  eventView">
            <a href={'/candidate/' + candidateData.rows[j]._id}>view</a>
          </td>
        </tr>,
      );
    }
    // setCandidateRows(candidateList);
    // setCandidateData(candidateData);
  };
  /**
   *
   *  set pagination and list rows
   */
  const selectedCalendarCol = selectedCol => {
    setselectedCol(selectedCol);
  };
  // attendance modal open
  const showAttendancemodal = value => {
    setshowmodal(value);
    window.location.reload();
  };
  return (
    <div className="EmployerList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className={props.isHomepage ? '' : 'card-header page-header '}>
            {!props.isHomepage && !calendar && (
              <div className="row">
                <div className="col-md-8">
                  <div className="page-title">Shifts </div>
                </div>
              </div>
            )}
            <div className="row align-items-center">
              {!props.isHomepage && (
                <div className="col-md-8 ">
                  <div className="row d-flex align-items-center">
                    {!calendar && (
                      <div className="col-md-6">
                        <div>
                          <div className="input-outer-div">
                            <input
                              className="search-input-div"
                              value={query.searchKey}
                              onChange={handleChange}
                              type="text"
                              name="searchKey"
                              aria-label="Search"
                              placeholder="Search here..."
                            />
                            <a className="search_bar">
                              <i
                                className="fa fa-search in_bar"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* //date picker div-- */}
                    <div className="col-md-3">
                      <div className={'mt-1'}>
                        {calendar ? (
                          <DatePicker
                            data-cy="calenderdate"
                            style={{
                              height: '40px',
                            }}
                            format="MMMM - YYYY"
                            name="calenderdate"
                            value={datevalue}
                            onChange={date => {
                              datechange(date);
                            }}
                            onlyMonthPicker
                          />
                        ) : (
                          <DatePicker
                            data-cy="calenderdate"
                            style={{
                              height: '40px',
                            }}
                            format="DD-MM-YYYY"
                            name="calenderdate"
                            value={datevalue}
                            onChange={date => {
                              datechange(date);
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {!IsBusinessUnit && !IsAdmin && !IsStaff && (
                      <div className="col-md-3">
                        <div className={'mt-1'}>
                          <select
                            className="select col-md-12 select-carehome"
                            name="bunit"
                            onChange={businessunitChange}
                          >
                            <option value="" hidden="true">
                              {' '}
                              select business unit
                            </option>
                            <option value=""> All</option>
                            {businessunitoptionsData}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* 7 days shifts detials */}
              {/* FROM DATE PICKER======== */}
              {!props.isHomepage && (
                <div className="col-md-4">
                  <div className="row d-flex justify-content-end align-items-center mt-1 assignfloat ">
                    {calendar && (
                      <>
                        <div className=" ml-2">
                          <div
                            onClick={() => showshifts()}
                            className={'active-icon-div mt-1'}
                          >
                            <img
                              src="/assets/img/bars_light.png"
                              style={{ height: '17px' }}
                            ></img>
                          </div>
                        </div>
                        <div className=" ml-2">
                          <div className={'icon-div mt-1'}>
                            <img
                              src="/assets/img/cal_black.png"
                              style={{ height: '25px' }}
                            ></img>
                          </div>
                        </div>
                      </>
                    )}
                    {/*----- place active button ----- */}
                    {shifts && (
                      <>
                        <div className=" ml-2">
                          <div className={'icon-div mt-1 card-body2'}>
                            <img
                              src="/assets/img/bars_dark.png"
                              style={{ height: '17px' }}
                            ></img>
                          </div>
                        </div>
                        <div className=" ml-2">
                          <div
                            onClick={() => showcalendar()}
                            className={'active-icon-div mt-1 card-body2'}
                          >
                            <img
                              src="/assets/img/cal_white.png"
                              style={{ height: '25px' }}
                            ></img>
                          </div>
                        </div>
                      </>
                    )}
                    {/* ------palce active button---- */}
                    <div className="ml-2">
                      <div
                        className="btn btn-primary-blue float-right mt-1 card-body2 btn-assign-shift-block-booking"
                        onClick={() => showmultipleshiftmodal()}
                      >
                        <img
                          src="/assets/img/pluss.png"
                          style={{ height: '12px' }}
                        ></img>
                        <p className="button-text"> Assign Shift </p>
                      </div>
                    </div>
                    {(IsAdmin || IsStaff) && (
                      <div className="ml-2">
                        <div
                          className="attendance-div card-body2 mt-1 p-2 btn-add-timesheet-button"
                          onClick={() => {
                            setshowmodal(true);
                          }}
                        >
                          <p className="f-10 add-timesheet"> Add Timesheet</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className=" ">
                <p>
                  {!props.isHomepage && (
                    <div className="col ">
                      {!calendar && (
                        <div className="text-secondary">
                          {' '}
                          Showing 7 days shifts details
                        </div>
                      )}
                    </div>
                  )}
                </p>
              </div>
              {/* card-body */}

              <div
                className={
                  !props.isHomepage
                    ? 'card-body  table-responsive main-card-body pl-0 pr-0'
                    : '  table-responsive main-card-body '
                }
              >
                {!isLoading && !calendar && (
                  //--------7 dasys calendar view-----------
                  <BlockBookingListView
                    shifts_from_date={shifts_from_date}
                    selectedbusinessunit={selectedbusinessunit}
                    showAllocateShiftListView={showAllocateShiftListView}
                    searchKey={query.searchKey}
                    IsAdmin={IsAdmin}
                    IsStaff={IsStaff}
                  ></BlockBookingListView>
                )}
                {!!isLoading && <LoadingImg />}
                {/* //------------full month calendar-------------- */}
                {!isLoading && calendar && (
                  <BlockBookingcalnedar
                    selectedCalendarCol={selectedCalendarCol}
                    showAllocateShift={showAllocateShift}
                    selectedbusinessunit={selectedbusinessunit}
                    shifts_from_date={shifts_from_date}
                    IsAdmin={IsAdmin}
                    IsStaff={IsStaff}
                  />
                )}
                {!candidateData && candidateData.rows && (
                  <p className="margin-top-20 text-center"> No result found </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setshowmodal(false);
                    }}
                  >
                    <i className="fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <AttendaceBloackbooking
                    selecteddate={selectedCol}
                    showAttendancemodal={showAttendancemodal}
                  ></AttendaceBloackbooking>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* //-------forcalendar single box plus button click */}
      {showAllocatemodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setshowAllocatemodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <ShiftsAdd
                  showAddshiftmodal={showAddshiftmodal}
                  allocateshift={true}
                  daymonthyear={daymonthyear}
                  isSelectUsershow={true}
                  iscarehome={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* //for multiple days shifts add  */}
      {showshiftAddmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setshowshiftAddmodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <ShiftsAdd
                  allocateshift={false}
                  isSelectUsershow={true}
                  showAddshiftmodal={showAddshiftmodal}
                  daymonthyear={daymonthyear}
                  isemployer={true}
                  isMultipleDays={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* //-----shifts add modal for calendar list view with user and date chosen---- */}
      {false && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <ShiftsAdd
                  showAddshiftmodal={showAddshiftmodal}
                  // allocateshift={true}
                  listViewShiftsAdd={true}
                  // daymonthyear={daymonthyear}
                  listViewShiftAddDate={listViewShiftAddDate}
                  isSelectUsershow={true}
                  iscarehome={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showErrorModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header no-border-bottom">
                <div className="close">
                  <a onClick={() => setshowErrorModal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="d-flex justify-content-center ">
                  {ModalError}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return {
    requestStatus,
    candidateData,
  };
}

const connectedBlockBookinglist = connect(mapStateToProps)(BlockBookinglist);
export { connectedBlockBookinglist as BlockBookinglist };
