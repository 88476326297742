/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { BusinessunitAdd } from '../BusinessUnit/BusinessunitAdd';
import AddNewBusinessUnitModal from '../EmployerSettings/EmployerSettingsContentComponents/AddNewBusinessUnitModal';

import { SectionAdd } from '../Section';
import {
  categoryActions,
  employerActions,
  sectionActions,
  staffActions,
  vacancyshiftActions,
} from '../_actions';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from '../_services';

const VacancyshiftAdd = props => {
  const [vacancyshift, setVacancyshift] = useState({
    no_of_openings: 1,
    is_bidding_option_needed: 0,
    is_auto_booking_confirm: 0,
    // is_break_time_pay: 0,
  });
  const [staffrows, setstaffrows] = useState();
  const [isEditcategory, setisEditcategory] = useState(false);
  const [isEditemployer, setisEditemployer] = useState(false);
  const [Disabled, setDisabled] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [IsEmployer, setIsEmployer] = useState(false);
  const [IsAdmin, setIsAdmin] = useState(false);
  const [IsBunit, setIsBunit] = useState(false);
  const [sectionData, setsectionData] = useState([]);
  const [category, setCategory] = useState({});
  const [shiftrows, setshiftrows] = useState([]);
  const [SectionAddModal, setSectionAddModal] = useState(false);
  const [BusinessunitAddModal, setBusinessunitAddModal] = useState(false);
  const [showAdditionalOptionsBlock, setshowAdditionalOptionsBlock] =
    useState(false);
  const [isParentEmployer, setisParentEmployer] = useState(false);
  const [businessunitoptionsData, setbusinessunitoptionsData] = useState();
  const [shiftTimeList, setShiftTImeList] = useState([]);
  const [error, setError] = useState({ common: '' });
  const [showModal, setShowModal] = useState(false);
  const [userSuccessMessage, setUserSuccessMessage] = useState(false);

  // eslint-disable-next-line no-unused-vars
  let value = false;
  let employer_id = '';
  let abc = '';
  const currentdate = new Date().toISOString().slice(0, 10);
  // const currTime = currentdate.getHours() * 60 + currentdate.getMinutes();
  const { id } = useParams();
  const currDate = new Date(); // Current date
  const formattedCurrDate = currDate.toISOString().split('T')[0];
  const totalSeconds =
    currDate.getHours() * 60 * 60 + // hours to seconds
    currDate.getMinutes() * 60 + // minutes to seconds
    currDate.getSeconds();

  // Calculate hours and minutes
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  // Format hours and minutes to 'hh:mm'
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;

  // effect to load vacancy shift , staffs , employer according to the condition
  useEffect(() => {
    if (id && id !== 0 && id !== '0') {
      query._id = id;
      setQuery(query);
      getAllVacancyshift(1, query);
      getAllstaff();
    }
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllVacancyshift(1, query);
      getAllstaff();
    }
    if (props.daymonthyear) {
      getcheck_in();
    }
    if (
      props.vacancy_id &&
      props.vacancy_id !== 0 &&
      props.vacancy_id !== '0'
    ) {
      query._id = props.vacancy_id;
      setQuery(query);
      getAllVacancyshift(1, query);
      getAllstaff();
    }
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id && user.role == 4) {
        setIsEmployer(true);
        if (user.is_parent) {
          setisParentEmployer(true);
        }
        setVacancyshift(prevstate => ({
          ...prevstate,
          employer_id: user.employer_id,
          employer_name: user.name,
        })),
          (employer_id = user.employer_id);
        getemployer(user.employer_id);
        getSections(user.employer_id);
        getbusinessunit();
        getAllstaff();
      }
      if (user && user.role == 1) {
        setIsAdmin(true);
      }
      if (user && user.role == 7) {
        setIsBunit(true);
        setVacancyshift(prevstate => ({
          ...prevstate,
          employer_id: user.employer_id,
          employer_name: user.name,
          businessunit: user.business_unit_id,
        })),
          (employer_id = user.employer_id);
        getSections();
        getbusinessunit();
        getemployer(user.employer_id);
        getAllstaff();
      }
      getAllstaff();
    }
  }, []);
  //
  useEffect(() => {
    if (vacancyshift._id) {
      vacancyshift.dates = [vacancyshift.from_date_string];
      vacancyshift.maximum_bidding_amount =
        vacancyshift.maximum_employer_bidding_amount;
      vacancyshift.is_bidding_option_needed =
        // eslint-disable-next-line no-self-assign
        vacancyshift.is_bidding_option_needed;
    }
    // if (!vacancyshift._id) {
    //   vacancyshift.publish_status = 1;
    // }
  }, [vacancyshift.employer_id]);
  //--------multipel date picker handle change ---------------

  const disabledDate = date => {
    const today = new Date();
    return date.isBefore(today, 'day');
  };

  const dateshandleChange = value => {
    if (vacancyshift._id) {
      setVacancyshift({
        ...vacancyshift,
        dates: [new Date(value).toISOString().split('T')[0]],
      });
    } else {
      let temp_dates = [];
      for (let d = 0; d < value.length; d++) {
        temp_dates.push(value[d].format('YYYY-MM-DD'));
      }
      setVacancyshift({
        ...vacancyshift,
        dates: temp_dates,
      });
    }
  };
  // call for get all staff
  const getAllstaff = () => {
    const { dispatch } = props;
    dispatch(staffActions.staffList({ select: ['_id', 'name'] })).then(res => {
      let staffData = res.data.rows;
      let stafflist = [];
      for (let i = 0; i < staffData.length; i++) {
        stafflist.push(
          <option value={staffData[i]._id}>{staffData[i].name}</option>,
        );
      }
      setstaffrows(stafflist);
    });
  };
  //---get al category for listing in table -------------
  const getAllCategory = category_id => {
    props
      .dispatch(
        categoryActions.categoryList({
          _id: category_id,
          select: ['_id', 'name', 'minimum_employer_price', 'minimum_price'],
        }),
      )
      .then(
        res => {
          if (res && res.data.rows && res.data.rows.length > 0) {
            setCategory(res?.data?.rows?.[0]);
          }
        },
        () => {
          setIsLoading(false);
          setError({ common: 'Error ' });
        },
      );
  };
  // effect to call category when vacancy shift category change
  useEffect(() => {
    getAllCategory(vacancyshift.category);
  }, [vacancyshift.category]);
  // call for employer list
  const getemployer = employer_id => {
    const { dispatch } = props;
    dispatch(
      employerActions.getEmployerListforasync({
        _id: employer_id,
        select: ['_id', 'break_time'],
      }),
    ).then(
      res => {
        setIsLoading(false);
        if (
          !props.vacancy_id &&
          !props.vacancy_id !== 0 &&
          !props.vacancy_id !== '0'
        ) {
          setVacancyshift(prevState => ({
            ...prevState,
          }));
        } else {
          setVacancyshift(prevState => ({
            ...prevState,
          }));
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // call for get bunit
  const getbusinessunit = employer_id => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        query.employer_id = user.employer_id;
      }
    }
    if (employer_id) {
      query.employer_id = employer_id;
    }
    let new_query = query;
    new_query.status = 1;
    delete new_query._id;
    delete new_query.attributes;
    new_query.select = ['_id', 'name'];
    fetch(apiConstants.apiUrl + '/getbusinessunitlist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify(new_query),
    }).then(response =>
      response.json().then(({ data }) => {
        if (data && data.rows) {
          let businessunit_rows = [];
          for (let s = 0; s < data.rows.length; s++) {
            businessunit_rows.push(
              <option
                // selected={s == 0 ? true : false}
                key={data.rows[s].name}
                value={data.rows[s]._id}
              >
                {data.rows[s].name}
              </option>,
            );
          }
          setbusinessunitoptionsData(businessunit_rows);
          if (
            !props.vacancy_id &&
            !props.vacancy_id !== 0 &&
            !props.vacancy_id !== '0'
          ) {
            setVacancyshift(prevState => ({
              ...prevState,
              // businessunit: data.rows[0]?._id,
              bunit_name: data.rows[0]?.name,
            }));
          }
        }
      }),
    );
  };
  // section modal open or close
  const closeSectionAddModal = value => {
    setSectionAddModal(value);
    getSections();
  };
  // bunit add modal open or close
  const closeBusinessunitAddModal = value => {
    setBusinessunitAddModal(value);
    getbusinessunit(vacancyshift.employer_id);
  };
  // show section modal add
  const showSectionaddModal = () => {
    setSectionAddModal(true);
  };
  // show bunit modal add
  const showBusinessunitaddModal = () => {
    setBusinessunitAddModal(true);
  };
  ///----------additonal option block show --------
  const showAdditionalOptionsBlockView = () => {
    setshowAdditionalOptionsBlock(!showAdditionalOptionsBlock);
  };
  //----------handle select employer function---------
  const selectedemployer = (selectedOption, event) => {
    let name = event.name;
    employer_id = selectedOption.value;
    setVacancyshift(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
    setVacancyshift(prevState => ({
      ...prevState,
      employer_name: selectedOption.label + ' ' + selectedOption.city,
      break_time: selectedOption.break_time,
      is_break_time_pay: selectedOption.pay_break,
    }));
    getbusinessunit(selectedOption.value);
    getSections(selectedOption.value);
  };
  //----------get employerlist ----------
  const getEmployerList = inputValue =>
    new Promise((resolve, reject) => {
      let parent_id = '';
      if (localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user.employer_id && user.role == 4) {
          parent_id = user.employer_id;
        }
      }
      fetch(apiConstants.apiUrl + '/getEmployerListforasync', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          searchKey: inputValue,
          status: 1,
          active_status: 1,
          parent_id: parent_id,
          select: ['_id', 'company_name', 'city', 'break_time'],
        }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            resolve(
              data.rows.map(({ _id, company_name, city, break_time }) => ({
                value: _id,
                label: company_name,
                city: city,
                break_time: break_time,
              })),
            );
          }),
        )
        .catch(reject);
    });
  // to check in
  const getcheck_in = () => {
    let fromdate =
      props.daymonthyear.year +
      '-' +
      (props.daymonthyear.month.toString().length == 1
        ? '0' + props.daymonthyear.month
        : props.daymonthyear.month) +
      '-' +
      (props.daymonthyear.day.toString().length == 1
        ? '0' + props.daymonthyear.day
        : props.daymonthyear.day);
    setVacancyshift({
      ...vacancyshift,
      from_date: fromdate,
      dates: [fromdate],
    });
  };
  // hide error
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  // handler for value change like radio button
  const onValueChange = event => {
    const { name, value } = event.target;
    setVacancyshift({
      ...vacancyshift,
      [name]: value,
    });
  };
  //
  const selectedcategory = (selectedOption, event) => {
    let name = event.name;
    setVacancyshift(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
  };
  // ------------get all section list -----------------------
  const getSections = employer_id => {
    //=-------employer_id is passed when admin vacancy add -------
    const { dispatch } = props;
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        query.employer_id = user.employer_id;
      }
    }
    if (employer_id) {
      query.employer_id = employer_id;
    }
    let new_query = query;
    delete new_query._id;
    delete new_query.select;
    new_query.attributes = [
      '_id',
      'name',
      'from_time',
      'to_time',
      'section_type',
    ];
    new_query.status = 1;
    delete new_query.pageVo;
    dispatch(sectionActions.sectionList(new_query)).then(res => {
      let shiftData = res.data.rows;
      setsectionData(res.data.rows);
      let shiftListWithoutHtml = [];
      let shiftlist = [];
      for (let i = 0; i < shiftData.length; i++) {
        shiftlist.push(
          <option
            // selected={i == 0 ? true : false}
            value={shiftData[i]._id}
          >
            {' '}
            {shiftData[i].name}{' '}
            {'(' +
              shiftData[i].from_time +
              '-' +
              shiftData[i].to_time +
              '/' +
              shiftData[i].section_type +
              ')'}
          </option>,
        );
        shiftListWithoutHtml.push(
          ' (' + shiftData[i].from_time + '-' + shiftData[i].to_time + ')',
        );
      }
      setshiftrows(shiftlist);
      setShiftTImeList(shiftListWithoutHtml);
      if (
        !props.vacancy_id &&
        !props.vacancy_id !== 0 &&
        !props.vacancy_id !== '0'
      ) {
        setVacancyshift(prevState => ({
          ...prevState,
          // shift: res?.data?.rows?.[0]?._id,
          section_type: res?.data?.rows?.[0]?.section_type,
          check_in_time: res?.data?.rows?.[0]?.from_time,
          check_out_time: res?.data?.rows?.[0]?.to_time,
        }));
      }
    });
  };
  /**
   * Get all vacancyshifts
   *
   * */
  const getAllVacancyshift = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(vacancyshiftActions.vacancyshiftList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          let newres = {
            ...res?.data?.rows?.[0],
            dates: [res?.data?.rows?.[0].from_date_string],
          };
          setVacancyshift(newres);
          getSections(res?.data?.rows?.[0].employer_id);
          getbusinessunit(res?.data?.rows?.[0].employer_id);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for event change and state vacancies
  const handleChange = event => {
    let timeDifference;
    const { name, value, checked } = event.target;
    setError({ common: '' });
    if (name == 'shift') {
      let selected_section_type = '';
      let check_in_time = '';
      let check_out_time = '';
      for (let s = 0; sectionData.length && s < sectionData.length; s++) {
        if (value == sectionData[s]._id) {
          selected_section_type = sectionData[s].section_type;
          check_in_time = sectionData[s].from_time;
          check_out_time = sectionData[s].to_time;
        }
      }
      // Calculate the time difference between check_in_time and check_out_time
      timeDifference = miscService.calculateTimeDifference(
        check_in_time,
        check_out_time,
      );
      setVacancyshift(vacancyshift => ({
        ...vacancyshift,
        section_type: selected_section_type,
        check_in_time: check_in_time,
        check_out_time: check_out_time,
        timeDifference: timeDifference,
        [name]: value,
      }));
    } else if (name == 'price' || name == 'employer_to_pay') {
      const regex = /^(?!\.)^(?!.*[+e])[\d\s]{0,3}(\.\d{0,2})?$/;
      const sanitizedValue = regex.test(value) ? value : '';

      if (sanitizedValue) {
        setVacancyshift(vacancyshift => ({
          ...vacancyshift,
          [name]: sanitizedValue,
        }));
      } else if (value === '') {
        setVacancyshift(vacancyshift => ({
          ...vacancyshift,
          [name]: value,
        }));
      } else {
        // If sanitizedValue is empty or null, replace letters in value before setting
        let digitCheck = /\d/.test(value);
        if (!digitCheck) {
          setVacancyshift(vacancyshift => ({
            ...vacancyshift,
            [name]: '',
          }));
        }
      }
    } else if (name == 'from_date') {
      setVacancyshift(vacancyshift => ({
        ...vacancyshift,
        from_date_string: value,
        [name]: value,
      }));
    } else if (name == 'is_auto_booking_confirm') {
      if (checked) {
        setVacancyshift(vacancyshift => ({
          ...vacancyshift,
          [name]: 1,
          is_bidding_option_needed: 0,
          maximum_bidding_amount: 0,
        }));
      } else {
        setVacancyshift(vacancyshift => ({
          ...vacancyshift,
          [name]: 0,
        }));
      }
    } else if (name == 'is_bidding_option_needed') {
      if (checked) {
        setVacancyshift(vacancyshift => ({
          ...vacancyshift,
          [name]: 1,
          is_auto_booking_confirm: 0,
        }));
      } else {
        setVacancyshift(vacancyshift => ({
          ...vacancyshift,
          [name]: 0,
          maximum_bidding_amount: 0,
        }));
      }
    } else if (name == 'businessunit') {
      const select = event.target;
      const bunitname = select.options[select.selectedIndex].text;
      setVacancyshift(vacancyshift => ({
        ...vacancyshift,
        [name]: value,
        bunit_name: bunitname,
      }));
    } else if (name == 'break_time') {
      const regex = /^(?!.*[+e])[\d\s]{0,3}$/;
      const sanitizedValue = regex.test(value) ? value : '';
      if (value === '' || sanitizedValue) {
        setVacancyshift(vacancyshift => ({
          ...vacancyshift,
          [name]: value,
        }));
      } else {
        let digitCheck = /\d/.test(value);
        if (!digitCheck) {
          setVacancyshift(vacancyshift => ({
            ...vacancyshift,
            [name]: '',
          }));
        }
      }
    } else if (name == 'no_of_openings') {
      const sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 2);
      const numericValue = parseInt(sanitizedValue);
      if (numericValue >= 1 && numericValue <= 10) {
        setVacancyshift(vacancyshift => ({
          ...vacancyshift,
          [name]: numericValue,
        }));
      } else if (sanitizedValue == '') {
        setVacancyshift(vacancyshift => ({
          ...vacancyshift,
          [name]: '',
        }));
      }
    } else {
      setVacancyshift(vacancyshift => ({
        ...vacancyshift,
        [name]: value,
      }));
    }
  };
  // increment counter for no.opening
  const incrementcounter = () => {
    setVacancyshift(prevState => ({
      ...prevState,
      no_of_openings: Math.min(Number(prevState.no_of_openings) + 1, 10),
    }));
  };
  // decrement counter for no.opening
  const decrementcounter = () => {
    setVacancyshift(prevState => ({
      ...prevState,
      no_of_openings: Math.max(prevState.no_of_openings - 1, 1),
    }));
  };

  // submit action for add vacancy
  const handleSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    setDisabled(true);
    setIsLoading(true);
    const { dispatch } = props;
    if (
      vacancyshift.dates &&
      vacancyshift.shift &&
      vacancyshift.no_of_openings &&
      vacancyshift.category &&
      vacancyshift.businessunit &&
      vacancyshift.employer_to_pay &&
      vacancyshift.break_time &&
      (vacancyshift.is_break_time_pay == 1 ||
        vacancyshift.is_break_time_pay == 0) &&
      (vacancyshift.publish_status == 1 ||
        vacancyshift.publish_status == 0 ||
        vacancyshift.publish_status == 2)
    ) {
      if (vacancyshift.dates && vacancyshift.dates.length) {
        for (let p = 0; p < vacancyshift.dates.length; p++) {
          if (vacancyshift.dates[p] < currentdate) {
            setError({ common: 'Choose a valid date' });
            setDisabled(false);
            setIsLoading(false);
            return;
          }
        }
      }
      if (
        vacancyshift.dates == formattedCurrDate &&
        vacancyshift.check_in_time <= formattedTime
      ) {
        setError({
          common: 'You cannot select past shift timings for today.',
        });
        setDisabled(false);
        setIsLoading(false);
        return;
      }
      if (vacancyshift.timeDifference >= 6 && vacancyshift.break_time < 20) {
        setError({
          common: 'The minimum break time should be 20 minutes or longer.',
        });
        setDisabled(false);
        setIsLoading(false);
        return;
      }
      if (vacancyshift.break_time && vacancyshift.break_time >= 180) {
        setError({
          common: 'Break time should be less than 180 minutes ',
        });
        setDisabled(false);
        setIsLoading(false);
        return;
      }
      if (vacancyshift.price && parseInt(vacancyshift.price) <= 0) {
        setError({ common: 'Price should be greater than zero' });
        setDisabled(false);
        setIsLoading(false);
        return;
      }
      if (
        vacancyshift.price &&
        parseFloat(vacancyshift.price) < parseFloat(category.minimum_price)
      ) {
        setError({
          common:
            'Price should be greater than minimum price of category ' +
            category.minimum_price,
        });
        setDisabled(false);
        setIsLoading(false);
        return;
      }
      if (
        vacancyshift.employer_to_pay &&
        vacancyshift.employer_to_pay < vacancyshift.price
      ) {
        setError({
          common: 'Employer Price should be greater than Candidate Pay',
        });
        setDisabled(false);
        setIsLoading(false);
        return;
      }
      if (vacancyshift.is_bidding_option_needed == 1) {
        vacancyshift.is_auto_booking_confirm = 0;
        if (!vacancyshift.maximum_bidding_amount) {
          setError({ common: 'maximum bidding amount required' });
          setDisabled(false);
          setIsLoading(false);
          return;
        }
        if (parseInt(vacancyshift.maximum_bidding_amount) <= 0) {
          setError({
            common: 'maximum bidding amount should be greater than zero',
          });
          setDisabled(false);
          setIsLoading(false);
          return;
        }
        if (
          vacancyshift.maximum_bidding_amount &&
          parseInt(vacancyshift.employer_to_pay) <=
            parseInt(vacancyshift.maximum_bidding_amount)
        ) {
          setError({
            common:
              'Employer pay should be greater than maximum bidding amount ' +
              vacancyshift.maximum_bidding_amount,
          });
          setDisabled(false);
          setIsLoading(false);
          return;
        }
      }
      setIsLoading(false);

      dispatch(vacancyshiftActions.vacancyshiftAdd(vacancyshift)).then(
        () => {
          setIsLoading(false);
          props.showsavemodal(false);
        },
        err => {
          setIsLoading(false);
          setDisabled(false);
        },
      );
    } else {
      setError({ common: 'Complete The Form' });

      setDisabled(false);
      setIsLoading(false);
    }
  };

  const handleUserSuccess = () => {
    setUserSuccessMessage(true);
    setTimeout(() => {
      setUserSuccessMessage(false);
    }, 5000);
  };

  return (
    <div className="VacancyshiftAdd d-flex justify-content-center">
      <div className="col-md-10 col-md-offset-2">
        <div className="card">
          <div className=" page-header ">
            <div className="page-title-content">
              <div className="row d-flex align-items-center">
                <div className="col-md-3">
                  <h4 className="card-title mt-0">
                    <p className="card-title">
                      {vacancyshift._id ? 'Edit' : 'Add'} Vacancies{' '}
                    </p>
                  </h4>
                </div>
                {props.daymonthyear && props.daymonthyear.day && (
                  <div className="col d-flex align-items-center">
                    {' '}
                    <div className="date-div">
                      {(props.daymonthyear.day.toString().length == 1
                        ? '0' + props.daymonthyear.day
                        : props.daymonthyear.day) +
                        '-' +
                        (props.daymonthyear.month.toString().length == 1
                          ? '0' + props.daymonthyear.month
                          : props.daymonthyear.month) +
                        '-' +
                        props.daymonthyear.year}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-body">
            <form name="form">
              <div className="row">
                {!props.daymonthyear && (
                  <div className="col-md-6 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Date : <span>*</span> &nbsp;
                      </label>
                      <DatePicker
                        multiple={vacancyshift._id ? false : true}
                        style={{
                          height: '44px',
                          borderRadius: '4px',
                          fontSize: '14px',
                          padding: '4px 10px',
                        }}
                        value={
                          vacancyshift._id
                            ? new Date(vacancyshift.dates[0])
                            : vacancyshift.from_date
                        }
                        format="DD-MM-YYYY"
                        minDate={new Date()}
                        showOtherDays={false}
                        onChange={dateshandleChange}
                      />
                      {submitted && !vacancyshift.dates && (
                        <div className="help-block"> Date is required</div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <label htmlFor="type">Number of Vacancies</label>
                  <div className="col-md-6 col-2 col-sm-2 pr-1 counter ">
                    <div>
                      <div className={' d-flex align-items-center'}>
                        <div className="count" onClick={decrementcounter}>
                          {' '}
                          -{' '}
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control-custome text-center "
                            name="no_of_openings"
                            placeholder=""
                            value={vacancyshift.no_of_openings}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="count" onClick={incrementcounter}>
                          {' '}
                          +{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                  {submitted && !vacancyshift.no_of_openings && (
                    <div className="help-block">
                      {' '}
                      No of openings is required{' '}
                    </div>
                  )}
                </div>
                <div className="col-md-6 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">
                      Jobrole : <span>*</span>
                    </label>
                    {vacancyshift._id && isEditcategory && (
                      <AsyncSelect
                        name="category"
                        className="categoryselect"
                        onChange={selectedcategory}
                        cacheOptions
                        defaultOptions
                        defaultInputValue={
                          vacancyshift &&
                          vacancyshift['Category.name'] &&
                          vacancyshift['Category.name']
                        }
                        noOptionsMessage={() => 'No results found'}
                        loadOptions={getcategorylist}
                      />
                    )}
                    {/* //-------for save ----------- */}
                    {!vacancyshift._id && !isEditcategory && (
                      <AsyncSelect
                        name="category"
                        className="categoryselect"
                        onChange={selectedcategory}
                        cacheOptions
                        defaultOptions
                        defaultInputValue={
                          vacancyshift &&
                          vacancyshift['Category.name'] &&
                          vacancyshift['Category.name']
                        }
                        noOptionsMessage={() => 'No results found'}
                        loadOptions={getcategorylist}
                      />
                    )}
                    {/* //---for edit category ----------- */}
                    {vacancyshift._id && !isEditcategory && (
                      <div className="col">
                        <div className="row">
                          <div className=" col-md-10 select_box1">
                            {' '}
                            {vacancyshift['Category.name']}
                          </div>
                          <div
                            className="edit_text"
                            onClick={() => {
                              setisEditcategory(true);
                            }}
                          >
                            Edit
                          </div>
                        </div>
                      </div>
                    )}
                    {submitted && !vacancyshift.category && (
                      <div className="help-block"> Category is required</div>
                    )}
                  </div>
                </div>
              </div>
              {!IsBunit && !IsEmployer && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      {!IsEmployer && (
                        <label htmlFor="type">
                          Select Employer : <span>*</span>
                        </label>
                      )}
                      <div
                        className={
                          'form-group ' +
                          (submitted && !vacancyshift.employer_id
                            ? ' has-error'
                            : '')
                        }
                      >
                        {vacancyshift._id && isEditemployer && (
                          <AsyncSelect
                            name="employer_id"
                            className="employerselect"
                            onChange={selectedemployer}
                            defaultInputValue={
                              vacancyshift &&
                              vacancyshift['Employer.company_name'] &&
                              vacancyshift['Employer.company_name']
                            }
                            cacheOptions
                            defaultOptions
                            noOptionsMessage={() => 'No results found'}
                            loadOptions={getEmployerList}
                          />
                        )}
                        {!vacancyshift._id && !isEditemployer && (
                          <AsyncSelect
                            name="employer_id"
                            className="employerselect"
                            onChange={selectedemployer}
                            defaultInputValue={
                              vacancyshift &&
                              vacancyshift['Employer.company_name'] &&
                              vacancyshift['Employer.company_name']
                            }
                            cacheOptions
                            defaultOptions
                            noOptionsMessage={() => 'No results found'}
                            loadOptions={getEmployerList}
                          />
                        )}
                        {vacancyshift._id && !isEditemployer && (
                          <div className="col">
                            <div className="row">
                              <div className=" col-md-10 select_box1">
                                {' '}
                                {vacancyshift['Employer.company_name']}
                              </div>
                              <div
                                className="edit_text"
                                onClick={() => {
                                  setisEditemployer(true);
                                }}
                              >
                                Edit
                              </div>
                            </div>
                          </div>
                        )}
                        {submitted && !vacancyshift.employer_id && (
                          <div className="help-block">Employer is required</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-5 pr-1">
                  <label htmlFor="type">
                    Shift Time : <span>*</span>
                  </label>
                  {vacancyshift.employer_id && (
                    <i
                      onClick={showSectionaddModal}
                      className="fa fa-plus-circle"
                      style={{
                        fontSize: '20px',
                        color: ' #3388EB',
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                    ></i>
                  )}
                  <div className={'form-group '}>
                    <select
                      className="select col-md-12 selectDesign"
                      name="shift"
                      value={vacancyshift.shift}
                      onChange={handleChange}
                    >
                      <option value="" selected>
                        {' '}
                        Select Shift Time
                      </option>
                      {shiftrows}
                    </select>
                    {/*Required*/}
                    {submitted && !vacancyshift.shift && (
                      <div className="help-block"> Shift is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                {!IsBunit && (
                  <div className="col-md-12 pr-1">
                    <label htmlFor="type">
                      Business Unit : <span>*</span>
                    </label>
                    {vacancyshift.employer_id && (
                      <i
                        // onClick={showBusinessunitaddModal}
                        onClick={() => setShowModal(true)}
                        className="fa fa-plus-circle"
                        style={{
                          fontSize: '20px',
                          color: ' #3388EB',
                          cursor: 'pointer',
                          marginLeft: '10px',
                        }}
                      ></i>
                    )}

                    <div className={'form-group '}>
                      <select
                        className="select col-md-12 select-business-unit"
                        name="businessunit"
                        value={vacancyshift.businessunit}
                        onChange={handleChange}
                      >
                        <option value="" selected>
                          {' '}
                          Select
                        </option>
                        {businessunitoptionsData}
                      </select>
                      {submitted && !vacancyshift.businessunit && !IsBunit && (
                        <div className="help-block">
                          Business Unit is required
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {IsAdmin &&
                vacancyshift.allocated_staff &&
                !props.vacancy_id == 0 &&
                props.showsavemodal && (
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group mb-4'}>
                        <label htmlFor="type">
                          Allocated Staff : <span></span>
                        </label>
                        <select
                          className="select col-md-12 selectDesign"
                          name="allocated_staff"
                          value={vacancyshift.allocated_staff}
                          onChange={handleChange}
                        >
                          <option value=""> Select Staff</option>
                          {staffrows}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              {IsAdmin && props.vacancy_id == 0 && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type">
                        Allocated Staff : <span></span>
                      </label>
                      <select
                        className="select col-md-12 selectDesign"
                        name="allocated_staff"
                        value={vacancyshift.allocated_staff}
                        onChange={handleChange}
                      >
                        <option value=""> Select Staff</option>
                        {staffrows}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {!IsEmployer && !IsBunit && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Candidate Pay : <span>*</span>
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          step="any"
                          className="form-control form-group-50px"
                          name="price"
                          disabled={IsEmployer}
                          placeholder="Price"
                          value={vacancyshift.price}
                          onChange={handleChange}
                        />
                        {submitted &&
                          !vacancyshift.price &&
                          !IsEmployer &&
                          !IsBunit && (
                            <div className="help-block">Price is required</div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">
                      Employer price / Flexi price : <span>*</span>
                    </label>
                    <div className="form-group">
                      <input
                        type="text"
                        step="any"
                        className="form-control form-group-50px"
                        name="employer_to_pay"
                        placeholder="Employer Price "
                        value={vacancyshift.employer_to_pay}
                        onChange={handleChange}
                      />
                      <small>
                        *include Employer NI,Holiday pay, Admin charge{' '}
                      </small>
                      {submitted && !vacancyshift.employer_to_pay && (
                        <div className="help-block">
                          Employer Price is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <label htmlFor="type">Break Paid</label>
                  <div className={'form-group '}>
                    <label style={{ marginRight: '20px' }}>
                      <input
                        name="is_break_time_pay"
                        style={{ marginRight: '5px' }}
                        type="radio"
                        value={1}
                        checked={
                          vacancyshift.is_break_time_pay === 1
                            ? true
                            : undefined
                        }
                        onChange={onValueChange}
                      />
                      Yes
                    </label>
                    <label style={{ marginRight: '20px' }}>
                      <input
                        name="is_break_time_pay"
                        style={{ marginRight: '5px' }}
                        type="radio"
                        value={0}
                        checked={
                          vacancyshift.is_break_time_pay === 0
                            ? true
                            : undefined
                        }
                        onChange={onValueChange}
                      />
                      No
                    </label>
                    {submitted &&
                      vacancyshift.is_break_time_pay != 1 &&
                      vacancyshift.is_break_time_pay != 0 && (
                        <div className="help-block">
                          {' '}
                          Break Paid is required
                          {vacancyshift.is_break_time_pay}
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <label htmlFor="type">Publish Status</label>
                  <div className={'form-group'}>
                    <label style={{ marginRight: '20px' }}>
                      <input
                        name="publish_status"
                        style={{ marginRight: '5px' }}
                        type="radio"
                        value={1}
                        checked={vacancyshift.publish_status == 1}
                        onChange={onValueChange}
                      />
                      Publish
                    </label>
                    <label style={{ marginRight: '20px' }}>
                      <input
                        name="publish_status"
                        style={{ marginRight: '5px' }}
                        type="radio"
                        value={0}
                        checked={vacancyshift.publish_status == 0}
                        onChange={onValueChange}
                      />
                      Draft
                    </label>
                    <label style={{ marginRight: '20px' }}>
                      <input
                        name="publish_status"
                        style={{ marginRight: '5px' }}
                        type="radio"
                        value={2}
                        checked={vacancyshift.publish_status == 2}
                        onChange={onValueChange}
                      />
                      Only subscribed agencies
                    </label>
                    {submitted &&
                      vacancyshift.publish_status != 1 &&
                      vacancyshift.publish_status != 0 &&
                      vacancyshift.publish_status != 2 && (
                        <div className="help-block">
                          {' '}
                          Publish Status is required
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">
                      Break Time (mins) : <span>*</span>
                    </label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="break_time"
                        placeholder="Enter Here"
                        value={vacancyshift.break_time}
                        onChange={handleChange}
                      />
                    </div>
                    {submitted && !vacancyshift.break_time && (
                      <div className="help-block"> Break Time is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Note</label>
                    <textarea
                      name="note"
                      placeholder="Note"
                      value={vacancyshift.note}
                      onChange={handleChange}
                      className="form-control"
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </div>
              {/* //----------additional options------------ */}
              <div className="row">
                <div
                  className="cursor_pointer col-md-12 pr-1"
                  onClick={showAdditionalOptionsBlockView}
                >
                  <label className="cursor_pointer">Additional Options </label>
                  &ensp;
                  {showAdditionalOptionsBlock && (
                    <label className="cursor_pointer">
                      <i
                        className="fa fa-angle-up"
                        style={{
                          fontSize: '18px',
                          color: ' #000',
                          cursor: 'pointer',
                        }}
                      ></i>
                    </label>
                  )}
                  {!showAdditionalOptionsBlock && (
                    <label className="cursor_pointer">
                      <i
                        className="fa fa-angle-down"
                        style={{
                          fontSize: '18px',
                          color: ' #000',
                          cursor: 'pointer',
                        }}
                      ></i>
                    </label>
                  )}
                </div>
              </div>
              {showAdditionalOptionsBlock && (
                <>
                  <div className="row">
                    <div>
                      <div className=" col-md-12 pr-1 lh-20">
                        <label style={{ marginRight: '20px' }}>
                          <input
                            name="is_auto_booking_confirm"
                            style={{ marginRight: '5px' }}
                            type="checkbox"
                            value={1}
                            onChange={handleChange}
                            checked={
                              vacancyshift.is_auto_booking_confirm == '1'
                            }
                          />
                          is auto booking confirm
                        </label>
                        <div>
                          <small>
                            *Automatically confirm booking (first in first out)
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  {vacancyshift.is_auto_booking_confirm == 0 && (
                    <div className="row">
                      <div>
                        <div className=" col-md-12 pr-1 lh-20">
                          <label style={{ marginRight: '20px' }}>
                            <input
                              name="is_bidding_option_needed"
                              style={{ marginRight: '5px' }}
                              type="checkbox"
                              value={0}
                              onChange={handleChange}
                              checked={
                                vacancyshift.is_bidding_option_needed == 1
                              }
                            />
                            Allow bidding
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  {vacancyshift.is_bidding_option_needed == 1 && (
                    <div className="row">
                      <div className=" col-md-12 pr-1 ">
                        <div className={'form-group '}>
                          <label>
                            Maximum Bidding Amount : <span>*</span>
                          </label>
                          <div className="form-group">
                            <input
                              type="number"
                              step="any"
                              className="form-control form-group-50px"
                              name="maximum_bidding_amount"
                              placeholder="maximum bidding amount"
                              value={vacancyshift.maximum_bidding_amount}
                              onChange={handleChange}
                            />
                            {submitted &&
                              vacancyshift.is_bidding_option_needed == 1 &&
                              !vacancyshift.maximum_bidding_amount && (
                                <div className="help-block">
                                  maximum bidding amount is required
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group">
                    <div className="d-flex float-right">
                      <div
                        data-cy="addvacancybtn"
                        disabled={Disabled}
                        onClick={Disabled ? '' : handleSubmit}
                        className=" btn-primary-blue-border float-right"
                      >
                        Save
                        {isLoading && (
                          <div className="lds-ring ml-2">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {SectionAddModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setSectionAddModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-12">
                      <SectionAdd
                        employer_id={vacancyshift.employer_id}
                        closeSectionAddModal={closeSectionAddModal}
                        isAdd={true}
                        shiftTimeRows={shiftTimeList}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {BusinessunitAddModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setBusinessunitAddModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-12">
                      <BusinessunitAdd
                        employer_id={vacancyshift.employer_id}
                        closeBusinessunitAddModal={closeBusinessunitAddModal}
                        isAdd={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* create new business unit modal */}
      {showModal && (
        <div className="modal-overlay hide-scrollbar">
          <div className="popup">
            <AddNewBusinessUnitModal
              employerId={employer_id}
              setShowModal={setShowModal}
              title="Add New Business Unit"
              onSuccess={handleUserSuccess}
            />
          </div>
        </div>
      )}
      {userSuccessMessage && (
        <div className="alert alertboxCustomSuccess">
          <span
            className="alert-close"
            onClick={() => setUserSuccessMessage(null)}
          >
            X
          </span>
          Successfully Created
          <p></p>
        </div>
      )}
    </div>
  );
};
// loader for categories in the select box options
const getcategorylist = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcategoryList', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputValue,
        select: ['_id', 'name', 'minimum_employer_price', 'minimum_price'],
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.rows.map(
              ({
                _id,
                name,
                morning_price,
                night_price,
                evening_price,
                day_price,
                weekend_price,
                holiday_price,
              }) => ({
                value: _id,
                label: name,
                morning_price: morning_price,
                night_price: night_price,
                evening_price: evening_price,
                day_price: day_price,
                weekend_price: weekend_price,
                holiday_price: holiday_price,
              }),
            ),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, vacancyshiftData } = state.vacancyshift;
  return {
    requestStatus,
    vacancyshiftData,
  };
}

const connectedVacancyshiftAdd = connect(mapStateToProps)(VacancyshiftAdd);
export { connectedVacancyshiftAdd as VacancyshiftAdd };
